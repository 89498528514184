import 'unpoly/dist/unpoly'
import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/tab'
import 'bootstrap/js/dist/carousel'
import lottie from 'lottie-web';
import smoothscroll from 'smoothscroll-polyfill';
import Cookies from 'js-cookie'
import {gsap} from "gsap"
import hyperform from 'hyperform';
import Isotope from 'isotope-layout'
import Cleave from 'cleave.js';

require('cleave.js/dist/addons/cleave-phone.ch');
import './components/show_cases'
import './components/pong'

import MapMarker from '../images/map-marker.svg';

up.compiler('body', () => {
    $('html').removeClass('no-js')
    smoothscroll.polyfill();
})

up.compiler('#js-logo', (element) => {
    const animation = lottie.loadAnimation({
        container: element,
        autoplay: false,
        loop: false,
        renderer: 'svg',
        path: '/animations/logo.json'
    });

    animation.addEventListener('DOMLoaded', function () {
        $(element).on('mouseenter', () => {
            animation.goToAndPlay(1, true)
        })
    });
})

up.compiler('#js-logo-small', (element) => {
    const animation = lottie.loadAnimation({
        container: element,
        autoplay: false,
        loop: false,
        renderer: 'svg',
        path: '/animations/logo-small.json'
    });

    animation.addEventListener('DOMLoaded', function () {
        $(element).on('mouseenter', () => {
            animation.goToAndPlay(1, true)
        })
    });
})

up.compiler('.js-menu-toggle', (element) => {
    const $toggle = $(element);
    const $brand = $('.navbar-brand')
    const $menu = $($toggle.attr('href'));
    const $menuItems = $menu.find('.js-menu-item');
    const $links = $menu.find('.js-menu-link')

    const animation = lottie.loadAnimation({
        container: $toggle[0],
        autoplay: false,
        loop: false,
        renderer: 'svg',
        path: '/animations/burger.json'
    });

    animation.addEventListener('DOMLoaded', function () {
        $toggle.on('mouseenter', () => {
            if (!animation.isPaused || $toggle.hasClass('-active'))
                return;
            animation.playSegments([0, 40], true);
        }).on('mouseleave', () => {
            if (!animation.isPaused || $toggle.hasClass('-active'))
                return;
            animation.playSegments([41, 70], true);
        })
    });

    const show = () => {
        animation.playSegments([600, 670], true);
        $toggle.addClass('-active')
        $brand.addClass('-menu-open')

        gsap.to($menu, {
            opacity: 1,
            visibility: 'visible'
        })

        $menuItems.each((idx, element) => {
            gsap.fromTo(element, {
                    x: '-100%',
                },
                {
                    x: 0,
                    delay: 0.1 * idx,
                    onComplete: () => $('.language-change').show()
                })
        })
    }

    const hide = () => {
        animation.playSegments([680, 710], true);
        $toggle.removeClass('-active')
        $brand.removeClass('-menu-open')

        $('.language-change').hide()
        $($menuItems.get().reverse()).each((idx, element) => {
            gsap.to(element, {
                x: '100%',
                delay: 0.1 * idx
            })
        })

        gsap.to($menu, {
            opacity: 0,
            visibility: 'hidden',
            delay: 1,
        })
    }

    $toggle.on('click', (event) => {
        event.preventDefault();
        if ($toggle.hasClass('-active')) {
            hide()
        } else {
            show()
        }
    })

    $links.on('click', function (event) {
        event.preventDefault()

        window.scroll({top: 0, left: 0, behavior: 'auto'});

        up.visit($(this).attr('href')).then(() => {
            hide()
        });
    })

    if (window.matchMedia('(any-hover: hover)').matches) {
        $links.on('mouseover', (event) => {
            if (window.matchMedia('(min-width: 992px)')) {
                if ($links[0] === event.currentTarget) {
                    $brand.addClass('-white')
                }
                if ($links.last()[0] === event.currentTarget) {
                    $toggle.addClass('-white')
                }
            } else {
                $brand.addClass('-white');
                $toggle.addClass('-white')
            }
        }).on('mouseleave', (event) => {
            if (window.matchMedia('(min-width: 992px)')) {
                if ($links[0] === event.currentTarget) {
                    $brand.removeClass('-white')
                }
                if ($links.last()[0] === event.currentTarget) {
                    $toggle.removeClass('-white')
                }
            } else {
                $brand.removeClass('-white')
                $toggle.removeClass('-white')
            }
        })
    }

    return () => {
        $toggle.off('click')
        $links.off('click')
        $links.off('mouseover')
        $links.off('mouseleave')
    }
})

up.compiler('#js-cookie-actions', (element) => {
    const $accept = $('#js-cookie-accept');
    const $firstDecline = $('#js-cookie-decline-first')

    const activateTracking = () => {
        if ($('body').data('environment') !== 'production') return;

        // Google Analytics Tracking
        if ($(element).data('analytics')) {
            let Analytics = document.createElement('script');
            Analytics.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${$(element).data('analytics')}`);
            Analytics.setAttribute('id', 'js-analytics-script')
            document.head.appendChild(Analytics);
            let AnalyticsData = document.createElement('script');
            AnalyticsData.setAttribute('id', 'js-analytics-data-script')
            AnalyticsData.text = `window.dataLayer = window.dataLayer || [];
                                function gtag(){dataLayer.push(arguments);}
                                gtag('js', new Date());
                                gtag('config', '${$(element).data('analytics')}');`;
            if ($(element).data('analytics-2')) {
                AnalyticsData.text += `
                    gtag('config', '${$(element).data('analytics-2')}');
                    gtag('event', 'conversion', {'send_to': '${$(element).data('analytics-2')}/ood9CImq54sDEMy06rwC'});`
            }
            document.head.appendChild(AnalyticsData);
        }

        // Hotjar Tracking
        if ($(element).data('hotjar')) {
            let hotjarTrackingData = document.createElement('script');
            hotjarTrackingData.setAttribute('id', 'js-hotjar-script')
            hotjarTrackingData.text = `
                                (function(h,o,t,j,a,r){
                                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                                    h._hjSettings={hjid:${$(element).data('hotjar')},hjsv:6};
                                    a=o.getElementsByTagName('head')[0];
                                    r=o.createElement('script');r.async=1;
                                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                    a.appendChild(r);
                                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                                `;
            document.head.appendChild(hotjarTrackingData);
        }
    }

    const removeTracking = () => {
        // Google Analytics Tracking
        if ($(element).data('analytics')) {
            $('#js-analytics-script,#js-analytics-data-script').remove()
        }

        // Hotjar Tracking
        if ($(element).data('hotjar')) {
            $('#js-hotjar-script').remove()
        }
    }

    // cookie dialog shown already?
    if (Cookies.get('cookies_accepted') === 'true' || Cookies.get('cookies_accepted') === 'false') {
        if (Cookies.get('cookies_accepted') === 'true') activateTracking()
        return $(element).parent().remove();
    } else {
        $accept.on('click', (event) => {
            event.preventDefault()
            Cookies.set('cookies_accepted', 'true');
            activateTracking()

            $(element).parent().fadeOut(() => {
                $(element).parent().remove()
            });
        })

        $firstDecline.on('click', (event) => {
            event.preventDefault()
            Cookies.set('cookies_accepted', 'false');

            $(element).parent().fadeOut(() => {
                $(element).parent().remove()
            });
        })
    }

    return () => {
        $accept.off('click')
        $firstDecline.off('click')
        removeTracking()
    }
})

up.compiler('#js-splash-screen', (element) => {
    // be sure to not have a splash-screen in the cache
    if (Cookies.get('splash_shown') === 'true') {
        return $(element).remove()
    }

    $('.cookie-notification').css('z-index', 1060);

    const hide = () => {
        $(element).fadeOut(() => {
            Cookies.set('splash_shown', 'true');
            $(element).remove()
        })
    }

    $(element).on('click', () => {
        hide()
    })

    setTimeout(hide, 3000);
})

up.compiler('#js-scroll-down', (element) => {
    const animation = lottie.loadAnimation({
        container: element,
        autoplay: true,
        loop: true,
        renderer: 'svg',
        path: '/animations/scroll-down.json'
    });
})

up.compiler('.js-hover-headline', (element) => {
    let width = $(element).find('span').width() / 2;

    if (window.matchMedia('(min-width: 992px)').matches) {
        $(element).parent().find('.chevron').css('right', `calc(50% - 4rem - ${width}px)`);
    } else {
        $(element).parent().find('.chevron').css('right', `calc(50% - 1.5rem - ${width}px)`);
    }
})

up.compiler('#js-switches', (element) => {
    const $switches = $(element).find('.js-switch');

    $switches.on('change', (event) => {
        if ($switches.filter(':checked').length > 2) {
            $switches.eq($switches.index(event.target) - 1).prop('checked', false);
        }
    })

    return () => $switches.off('change')
})

up.compiler('form', () => {
    hyperform.addTranslation("de", {
        TextTooLong: "Bitte kürzen Sie diesen Text auf maximal %l Zeichen (Sie verwenden derzeit %l Zeichen).",
        TextTooShort: "Bitte verwenden Sie zumindest %l Zeichen (Sie verwenden derzeit %l Zeichen).",
        ValueMissing: "Bitte füllen Sie dieses Feld aus.",
        CheckboxMissing: "Bitte klicken Sie dieses Kästchen an, wenn Sie fortsetzen wollen.",
        RadioMissing: "Bitte wählen Sie eine dieser Optionen.",
        FileMissing: "Bitte wählen Sie eine Datei.",
        SelectMissing: "Bitte wählen Sie einen Eintrag in der Liste.",
        InvalidEmail: "Bitte geben Sie eine E-Mail-Adresse ein.",
        InvalidURL: "Bitte geben Sie eine Internetadresse ein.",
        PatternMismatch: "Bitte halten Sie sich an das vorgegebene Format.",
        PatternMismatchWithTitle: "Bitte halten Sie sich an das vorgegebene Format: %l.",
        NumberRangeOverflow: "Bitte wählen Sie einen Wert, der nicht größer ist als %l.",
        DateRangeOverflow: "Bitte wählen Sie einen Wert, der nicht später ist als %l.",
        TimeRangeOverflow: "Bitte wählen Sie einen Wert, der nicht später ist als %l.",
        NumberRangeUnderflow: "Bitte wählen Sie einen Wert, der nicht kleiner ist als %l.",
        DateRangeUnderflow: "Bitte wählen Sie einen Wert, der nicht früher ist als %l.",
        TimeRangeUnderflow: "Bitte wählen Sie einen Wert, der nicht früher ist als %l.",
        StepMismatch: "Bitte wählen Sie einen gültigen Wert. Die zwei nähesten gültigen Werte sind %l und %l.",
        StepMismatchOneValue: "Bitte wählen Sie einen gültigen Wert. Der näheste gültige Wert ist %l.",
        BadInputNumber: "Bitte geben Sie eine Nummer ein.",
        "Please match the requested type.": "Bitte passen Sie die Eingabe dem geforderten Typ an.",
        "Please comply with all requirements.": "Bitte erfüllen Sie alle Anforderungen.",
        "Please lengthen this text to %l characters or more (you are currently using %l characters).": "Bitte verlängern Sie diesen Text auf mindestens %l Zeichen (Sie verwenden derzeit %l Zeichen).",
        "Please use the appropriate format.": "Bitte verwenden Sie das passende Format.",
        "Please enter a comma separated list of email addresses.": "Bitte geben Sie eine komma-getrennte Liste von E-Mail-Adressen an.",
        "Please select a file of the correct type.": "Bitte wählen Sie eine Datei vom korrekten Typ.",
        "Please select one or more files.": "Bitte wählen Sie eine oder mehrere Dateien.",
        "any value": "jeder Wert",
        "any valid value": "jeder gültige Wert",
    });
    hyperform.setLanguage("de");
    hyperform(window);
})

up.compiler('.js-phone-field', (element) => {
    new Cleave(element, {
        phone: true,
        phoneRegionCode: 'ch'
    });
})

up.compiler('#js-map', (element, options) => {
    window.initializeMap = function initializeMap() {
        var map_canvas = element;
        if (map_canvas) {
            var pt = new google.maps.LatLng(options.lat, options.lng, 16);
            var map_options = {
                center: new google.maps.LatLng(options.lat, options.lng, 15),
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                scrollwheel: false,
                navigationControl: false,
                mapTypeControl: false,
                styles: [{
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [{"color": "#d3d3d3"}]
                }, {
                    "featureType": "transit",
                    "stylers": [{"color": "#808080"}, {"visibility": "off"}]
                }, {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [{"visibility": "on"}, {"color": "#b3b3b3"}]
                }, {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [{"color": "#ffffff"}]
                }, {
                    "featureType": "road.local",
                    "elementType": "geometry.fill",
                    "stylers": [{"visibility": "on"}, {"color": "#ffffff"}, {"weight": 1.8}]
                }, {
                    "featureType": "road.local",
                    "elementType": "geometry.stroke",
                    "stylers": [{"color": "#d7d7d7"}]
                }, {
                    "featureType": "poi",
                    "elementType": "geometry.fill",
                    "stylers": [{"visibility": "on"}, {"color": "#ebebeb"}]
                }, {
                    "featureType": "administrative",
                    "elementType": "geometry",
                    "stylers": [{"color": "#a7a7a7"}]
                }, {
                    "featureType": "road.arterial",
                    "elementType": "geometry.fill",
                    "stylers": [{"color": "#ffffff"}]
                }, {
                    "featureType": "road.arterial",
                    "elementType": "geometry.fill",
                    "stylers": [{"color": "#ffffff"}]
                }, {
                    "featureType": "landscape",
                    "elementType": "geometry.fill",
                    "stylers": [{"visibility": "on"}, {"color": "#efefef"}]
                }, {
                    "featureType": "road",
                    "elementType": "labels.text.fill",
                    "stylers": [{"color": "#696969"}]
                }, {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [{"visibility": "on"}, {"color": "#737373"}]
                }, {
                    "featureType": "poi",
                    "elementType": "labels.icon",
                    "stylers": [{"visibility": "off"}]
                }, {
                    "featureType": "poi",
                    "elementType": "labels",
                    "stylers": [{"visibility": "off"}]
                }, {
                    "featureType": "road.arterial",
                    "elementType": "geometry.stroke",
                    "stylers": [{"color": "#d6d6d6"}]
                }, {
                    "featureType": "road",
                    "elementType": "labels.icon",
                    "stylers": [{"visibility": "off"}]
                }, {}, {"featureType": "poi", "elementType": "geometry.fill", "stylers": [{"color": "#dadada"}]}]
            };
            var map = new google.maps.Map(map_canvas, map_options);
            new google.maps.Marker({
                position: pt,
                map: map,
                icon: {url: MapMarker, size: new google.maps.Size(40, 40)}
            });
        }
    }

    if (document.getElementById('js-map-javascript')) {
        window.initializeMap();
    } else {
        let script = document.createElement("script");
        script.id = 'js-map-javascript';
        script.type = "text/javascript";
        document.getElementsByTagName("head")[0].appendChild(script);
        script.src = `https://maps.googleapis.com/maps/api/js?key=${options.api_key}&callback=initializeMap`;
    }
})

up.compiler('video[data-autoplay]', (video) => {
    if (!$(video).is(':visible'))
        return;

    let playState = null;
    let playPromise = undefined;

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (!entry.isIntersecting) {
                if (playPromise !== undefined) {
                    playPromise.then(_ => {
                        // Automatic playback started!
                        // Show playing UI.
                        // We can now safely pause video...
                        video.pause();
                        playState = false;
                        playPromise = undefined
                    })
                } else {
                    video.pause();
                    playState = false;
                    playPromise = undefined
                }
            } else {
                playPromise = video.play();
                playState = true;
            }
        });
    }, {});

    observer.observe(video);

    const onVisibilityChange = () => {
        if (document.hidden || !playState) {
            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    // Automatic playback started!
                    // Show playing UI.
                    // We can now safely pause video...
                    video.pause();
                    playState = false;
                    playPromise = undefined
                })
            } else {
                video.pause();
                playState = false;
                playPromise = undefined
            }

        } else {
            playPromise = video.play();
            playState = true;
        }
    };

    let visibilityListener = document.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
        document.removeEventListener('visibilitychange', visibilityListener)
        observer.disconnect()
    }
});

up.compiler('.js-unmute-video', (element) => {
    const video = document.querySelector($(element).data('target'))

    $(element).on('click', () => {
        video.muted = !video.muted
        $(element).toggleClass('-unmuted')
    })

    video.addEventListener('loadeddata', () => {
        if (video.mozHasAudio ||
            Boolean(video.webkitAudioDecodedByteCount) ||
            Boolean(video.audioTracks && video.audioTracks.length)) {
            $(element).removeClass('d-none');
        }
    })

    return () => $(element).off('click')
})

up.compiler('.js-smooth-scroll', (element) => {
    const $element = $(element)

    $element.on('click', (event) => {
        event.preventDefault()
        document.querySelector($element.attr('href')).scrollIntoView({behavior: 'smooth'});
    })

    return () => $element.off('click')
})

up.compiler('.embedsocial-hashtag', () => {
    if (document.getElementById('EmbedSocialHashtagScript')) return

    let js = document.createElement('script');
    js.id = 'EmbedSocialHashtagScript';
    js.src = "https://embedsocial.com/cdn/ht.js";
    document.getElementsByTagName("head")[0].appendChild(js);
})

up.compiler('#js-projects-filter', (element) => {
    const url = new URL(window.location.href);
    const grid = new Isotope('#js-grid', {
        itemSelector: '.js-grid-item',
        percentPosition: true,
    });
    const $filters = $('#js-filter .nav-link')
    const $mobileFilter = $('#js-select-filter')

    $filters.on('click', (event) => {
        event.preventDefault()
        $filters.removeClass('active')

        let filterValue = $(event.target).addClass('active').attr('data-filter');
        grid.arrange({filter: filterValue})
    })

    $mobileFilter.on('change', () => {
        let filterValue = $mobileFilter.val()
        grid.arrange({filter: filterValue});
    }).trigger('change')

    if (url.searchParams.get('tag')) {
        element.scrollIntoView({behavior: 'smooth'});
    }
})

up.compiler('#js-animated-page-title', (_element, text) => {
    const originalTitle = document.title;
    let interval;

    function MovingTitle(writeText, interval, visibleLetters) {
        var _instance = {};

        var _currId = 0;
        var _numberOfLetters = writeText.length;

        function updateTitle() {
            _currId += 1;
            if (_currId > _numberOfLetters - 1) {
                _currId = 0;
            }

            var startId = _currId;
            var endId = startId + visibleLetters;
            var finalText;
            if (endId < _numberOfLetters - 1) {
                finalText = writeText.substring(startId, endId);
            } else {
                var cappedEndId = _numberOfLetters;
                endId = endId - cappedEndId;

                finalText = writeText.substring(startId, cappedEndId) + writeText.substring(0, endId);
            }

            document.title = finalText;
        }

        _instance.init = function () {
            return setInterval(updateTitle, interval);
        };

        return _instance;
    }

    const visibilityListener = (_e) => {
        let isPageActive = !document.hidden

        if (!isPageActive) {
            document.title = text;
            let mvngTitle = new MovingTitle(text, 1, 50);
            interval = mvngTitle.init()
        } else {
            if (interval !== null) {
                clearInterval(interval)
                interval = null;
            }
            document.title = originalTitle;
        }
    }

    document.addEventListener('visibilitychange', visibilityListener)

    return () => {
        document.removeEventListener('visibilitychange', visibilityListener)
        if (interval !== null) {
            clearInterval(interval)
            interval = null;
        }
    }
})

up.compiler('.g-recaptcha', (element) => {
    window.onRecaptchaLoad = () => {
        grecaptcha.render(element, {
            sitekey: element.dataset.sitekey,
            callback: () => {
                $(element).closest('form').find('button[type="submit"]').prop('disabled', false)
            },
            'expired-callback': () => {
                $(element).closest('form').find('button[type="submit"]').prop('disabled', true)
            },
            'error-callback': () => {
                $(element).closest('form').find('button[type="submit"]').prop('disabled', true)
            }
        });
        $(element).closest('form').find('button[type="submit"]').prop('disabled', true)
    }

    if (!document.getElementById('js-recaptcha-script')) {
        let script = document.createElement('script');
        script.id = 'js-recaptcha-script';
        script.src = 'https://www.google.com/recaptcha/api.js?render=explicit&onload=onRecaptchaLoad';
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    } else {
        window.onRecaptchaLoad()
    }
})
