import {gsap} from "gsap";
import Cookies from 'js-cookie'

up.compiler('#js-show-cases', (element) => {
    if (!window.matchMedia('(min-width: 992px)').matches) return

    const container = element;
    const slides = document.querySelectorAll(".js-show-case");
    const $next = $("#js-next");
    const $previous = $("#js-previous");

    let offsets = [];
    window.activeSlide = parseInt(Cookies.get('currentSlide') || 0);
    Cookies.remove('currentSlide');
    let iw = window.innerWidth;

    window.teaseSlide = setTimeout(() => {
        window.autoScroll = next(10);
    }, 4000);

    const toggleArrows = () => {
        if (window.activeSlide >= slides.length - 3) {
            $next.addClass('d-none');
        } else {
            $next.removeClass('d-none');
        }
        if (window.activeSlide === 0) {
            $previous.addClass('d-none');
        } else {
            $previous.removeClass('d-none');
        }
    }

    const animate = (duration, newSlide) => {
        if (newSlide === window.activeSlide || gsap.isTweening(container)) return;

        window.activeSlide = newSlide
        // be sure to not overscroll
        if (window.activeSlide > (slides.length - 3)) {
            window.activeSlide = (slides.length - 3);
        }
        toggleArrows()

        return gsap.to(container, duration, {x: offsets[window.activeSlide]});
    }

    const next = (duration = 0.5) => {
        let newSlide = window.activeSlide + 1
        newSlide = newSlide > (slides.length - 1) ? (slides.length - 1) : newSlide;

        return animate(duration, newSlide)
    }

    const previous = (duration = 0.5) => {
        let newSlide = window.activeSlide - 1
        newSlide = newSlide < 0 ? 0 : newSlide;

        return animate(duration, newSlide)
    }

    // update the element snap points
    const sizeIt = () => {
        offsets = [];
        iw = window.innerWidth;
        gsap.set('#js-show-cases .panels', {width: slides.length * (iw / 3)});
        for (let i = 0; i < slides.length; i++) {
            offsets.push(-slides[i].offsetLeft);
        }
        gsap.set(container, {x: offsets[window.activeSlide]});
    }

    sizeIt();
    toggleArrows();

    const wheel = (e) => {
        clearTimeout(window.teaseSlide)
        if (window.autoScroll && window.autoScroll.isActive()) return window.autoScroll.duration(.5);

        e.originalEvent.deltaY > 0 ? next() : previous()
    }

    $(window).on('wheel', wheel).on('resize', sizeIt);

    $next.on("click", () => {
        clearTimeout(window.teaseSlide)
        if (window.autoScroll && window.autoScroll.isActive()) return window.autoScroll.duration(.5);
        next()
    });
    $previous.on("click", () => {
        clearTimeout(window.teaseSlide)
        if (window.autoScroll && window.autoScroll.isActive()) return window.autoScroll.duration(.5);
        previous()
    });

    return () => {
        window.removeEventListener('wheel', wheel);
        window.removeEventListener('resize', sizeIt);
        $next.off('click');
        $previous.off('click');
    }
});

up.compiler('.js-show-case-link', (element) => {
    const $showCase = $(element).parents('.js-show-case');
    const $intro = $showCase.find('.js-show-case-intro');

    const disableSliding = () => {
        $(window).off('wheel');
        $(window).off('resize');
        $("#js-next").off('click');
        $("#js-previous").off('click');
    }

    if (!$intro.length)
        return;

    if (window.matchMedia('(min-width: 992px)').matches) {
        const draggable = $("#js-show-cases");

        $(element).on('click', (event) => {
            event.preventDefault();
            disableSliding()

            if (window.autoScroll && autoScroll.isActive()) window.autoScroll.kill()
            if (window.teaseSlide) clearTimeout(window.teaseSlide)

            let leftOffset = $showCase.offset().left
            $showCase.addClass('-animating')
            $showCase.css({left: leftOffset - draggable.position().left});

            Cookies.set('currentSlide', window.activeSlide)

            // show the intro-picture
            gsap.to($intro, {
                zIndex: 100000,
                scale: 1
            })

            // start the growing animation and the final transition
            gsap.to($showCase, 1, {
                width: window.innerWidth,
                height: window.innerHeight,
                left: -draggable.position().left,
                delay: 0.5,
                onComplete: () => {
                    window.scroll({top: 0, left: 0, behavior: 'auto'});
                    window.location.href = $(element).attr('href');
                    // up.visit($(element).attr('href'))
                }
            })
        })
    } else {
        $(element).on('click', (event) => {
            event.preventDefault();
            disableSliding()

            let topOffset = $showCase.offset().top - $(document).scrollTop()
            let leftOffset = $showCase.offset().left
            $showCase.addClass('-animating')
            $showCase.css({top: topOffset, left: leftOffset});


            // show the intro-picture
            gsap.to($intro, {
                zIndex: 100000,
            })

            // start the growing animation and the final transition
            gsap.to($showCase, 1, {
                height: window.innerHeight,
                top: 0,
                delay: 0.5,
                onComplete: () => {
                    window.scroll({top: 0, left: 0, behavior: 'auto'});
                    window.location.href = $(element).attr('href');
                    // up.visit($(element).attr('href'))
                }
            })
        })
    }

    return () => $(element).off('click')
})
