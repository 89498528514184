up.compiler('#js-pong', (canvas) => {
    const elementInViewport = (el)  =>{
        let top = el.offsetTop;
        let left = el.offsetLeft;
        let width = el.offsetWidth;
        let height = el.offsetHeight;

        while(el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            top < (window.pageYOffset + window.innerHeight) &&
            left < (window.pageXOffset + window.innerWidth) &&
            (top + height) > window.pageYOffset &&
            (left + width) > window.pageXOffset
        );
    }


    class Vector {
        constructor(x = 0, y = 0) {
            this.x = x;
            this.y = y;
        }

        // Vector length explanation
        // http://chortle.ccsu.edu/vectorlessons/vch04/vch04_4.html
        get len() {
            return Math.sqrt(this.x * this.x + this.y * this.y);
        }

        set len(value) {
            const fact = value / this.len;
            this.x *= fact;
            this.y *= fact;
        }
    }

    class Rectangle {
        constructor(width, height) {
            this.position = new Vector;
            this.size = new Vector(width, height);
        }

        get left() {
            return this.position.x - this.size.x / 2;
        }

        get right() {
            return this.position.x + this.size.x / 2;
        }

        get top() {
            return this.position.y - this.size.y / 2;
        }

        get bottom() {
            return this.position.y + this.size.y / 2;
        }
    }

    class Ball extends Rectangle {
        constructor() {
            super(10, 10);
            this.velocity = new Vector;
        }
    }

    class Player extends Rectangle {
        constructor(x, y) {
            super(15, 120);
            this.score = 0;
            this.velocity = 0;
        }
    }

    class Pong {
        constructor(canvas) {
            this._canvas = canvas;
            this._context = this._canvas.getContext('2d');

            this.ball = new Ball();
            this.reset();

            this.players = [
                new Player(),
                new Player()
            ];

            this.charPixel = 10;
            this.chars = [
                '111101101101111',
                '010010010010010',
                '111001111100111',
                '111001111001111',
                '101101111001001',
                '111100111001111',
                '111100111101111',
                '111001001001001',
                '111101111101111',
                '111101111001111',
            ]
                .map(number => {
                    // Create a new canvas and context
                    const canvas = document.createElement('canvas');
                    canvas.width = this.charPixel * 3;
                    canvas.height = this.charPixel * 5;

                    const context = canvas.getContext('2d');
                    context.fillStyle = '#6CFF00';

                    // Print each number pixel
                    number.split('').forEach((fill, i) => {
                        if (fill === '1') {
                            context.fillRect(
                                (i % 3) * this.charPixel,
                                (i / 3 | 0) * this.charPixel,
                                this.charPixel,
                                this.charPixel
                            );
                        }
                    });
                    return canvas;
                });

            // Update players position
            const boardToLimitSpace = 20;
            this.players[0].position.x = boardToLimitSpace;
            this.players[1].position.x = this._canvas.width - boardToLimitSpace;
            this.players[1].position.y = this._canvas.height / 2;

            // Animation loop
            let lastTime = 0;
            const callback = time => {
                if (time) {
                    this.update((time - lastTime) / 1000)
                }
                ;
                lastTime = time;
                requestAnimationFrame(callback);
            };
            callback(0);
        }

        reset() {
            this.ball.position.x = this._canvas.width / 2;
            this.ball.position.y = this._canvas.height / 2;

            // Ball will start moving when the canvas is clicked
            this.ball.velocity.x = 0;
            this.ball.velocity.y = 0;
        }

        start() {
            if (this.ball.velocity.x === 0 && this.ball.velocity.y === 0) {
                // http://stackoverflow.com/questions/16591144/js-game-shooting-in-random-directions
                var angle = (5 + 2 * Math.random()) / 4 * Math.PI;
                var vx = 200 * Math.cos(angle - Math.PI / 2) * (Math.random() > 0.5 ? 1 : -1);
                var vy = 200 * Math.sin(angle - Math.PI / 2) * (Math.random() > 0.5 ? 1 : -1);
                this.ball.velocity = new Vector(vx, vy);
            }
        }

        drawRect(rect) {
            this._context.fillStyle = "#6CFF00";
            this._context.fillRect(rect.left, rect.top, rect.size.x, rect.size.y);
        }

        collide() {
            // Make the ball bounce with the players
            this.players.forEach(player => {
                if (player.left < this.ball.right && player.right > this.ball.left &&
                    player.top < this.ball.bottom && player.bottom > this.ball.top) {
                    this.ball.velocity.x = -this.ball.velocity.x;
                    this.ball.velocity.len *= 1.10;
                }
            });
        }

        drawScore() {
            this._context.fillStyle = "#6CFF00";
            const align = this._canvas.width / 3; // Player score align
            const characterWidth = this.charPixel * 4;

            this.players.forEach((player, playerIndex) => {
                // Array of score numbers
                const scoreChars = player.score.toString().split('');

                // Character list offset
                const offset = align * (playerIndex + 1) - (characterWidth * scoreChars.length / 2) + (this.charPixel / 2);

                scoreChars.forEach((char, charIndex) => {
                    this._context.drawImage(
                        this.chars[char],
                        offset + charIndex * characterWidth,
                        20
                    )
                })
            })
        }

        drawMiddleLine() {
            this._context.beginPath();
            this._context.setLineDash([5, 20]);
            this._context.moveTo(this._canvas.width / 2, 0);
            this._context.lineTo(this._canvas.width / 2, this._canvas.height);
            this._context.closePath();
            this._context.strokeStyle = '#6CFF00';
            this._context.stroke();
        }

        update(dt) {
            // Update ball position
            this.ball.position.x += this.ball.velocity.x * dt;
            this.ball.position.y += this.ball.velocity.y * dt;

            // Make computer player follow the ball
            this.players[0].position.y = this.ball.position.y;

            // Move human player
            this.players[1].position.y += this.players[1].velocity * dt;

            // Don't let players continue moving after limits
            this.players.forEach((player, index) => {
                if (player.top < 0) {
                    this.players[index].position.y = this.players[index].size.y / 2;
                    this.players[index].velocity = 0;
                }

                if (player.bottom > this._canvas.height) {
                    this.players[index].position.y = (
                        this._canvas.height - this.players[index].size.y / 2
                    );
                    this.players[index].velocity = 0;
                }
            });

            // Make the ball bounce in the upper and lower limits
            if ((this.ball.top < 0 && this.ball.velocity.y < 0) ||
                (this.ball.bottom > this._canvas.height && this.ball.velocity.y > 0)) {
                this.ball.velocity.y = -this.ball.velocity.y;
            }

            this.collide();

            // Score points when ball touch the players limits
            if (this.ball.left < 0) {
                this.players[1].score++;
                this.reset();
            }

            if (this.ball.right > this._canvas.width) {
                this.players[0].score++;
                this.reset();
            }

            // Paint the canvas in black
            this._context.fillStyle = "#000";
            this._context.fillRect(0, 0, this._canvas.width, this._canvas.height);

            this.drawScore();
            this.drawMiddleLine();

            // Render the ball
            this.drawRect(this.ball);

            // Render each player board
            this.players.forEach(player => {
                this.drawRect(player);
            })
        }
    }

    window.addEventListener('keydown', event => {
        if (elementInViewport(canvas)) {
            // Key down handler
            if (event.keyCode === 40) {
                pongGame.players[1].velocity = 400;
                event.preventDefault()
                return false;
            }

            // Key up handler
            if (event.keyCode === 38) {
                pongGame.players[1].velocity = -400;
                event.preventDefault()
                return false;
            }

            // Space handler
            if (event.keyCode === 32) {
                pongGame.start();
                $('#js-pong-instructions').remove();
                event.preventDefault()
                return false;
            }
        }

        return true;
    });

    window.addEventListener('keyup', event => {
        if (event.keyCode === 40 || event.keyCode === 38) {
            pongGame.players[1].velocity = 0;
            event.preventDefault()
            return false;
        }

        return true
    });

    const pongGame = new Pong(canvas);
})
